import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-redirect',
   templateUrl: './redirect.component.html',
   //styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

   constructor() {
      window.localStorage.removeItem("userToken")
      window.localStorage.removeItem("userLevel")

      window.location.href = "auth"
    }

   ngOnInit(): void {
   }

}
