<div class="row">
  <div class="col-md-3 col-3 no-mobile">
    <div class="input-group mb-3">
      <select class="custom-select isMenuLength" data-toggle="tooltip" data-placement="top" title="Mostrar">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="-1">Todos</option>
      </select>
    </div>
  </div>

  <div class="col-md-9 col-sm-8 col-12">
    <div class="input-group mb-3">
      <input type="text" class="form-control isTblSearchInput" placeholder="Pesquisar">
      <div class="input-group-append">
        <button class="btn btn-light" type="button" data-toggle="tooltip" data-placement="top" title="Pesquisar">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
  </div>
</div>
