import { environment } from './../../../environments/environment';
import { Injectable, EventEmitter, Optional } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';

import { AppService } from './../../app.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class UploadService {
   private readonly API = `${environment.api}/files`
   isInit = new EventEmitter<boolean>()
   options = new EventEmitter<any>()
   callback: any
   progress: number

   constructor(
      private app: AppService,
      private http: HttpClient
   ) { }

   async getFiles(): Promise<any[]> {
      return await this.http.post<any[]>(this.API, {
         siteUrl: this.app.siteUrl,
         siteUploadPath: this.app.siteUploadPath
      }).toPromise()
   }

   initUpload(options: any) {
      //document.getElementById("selectionType").setAttribute('value', options.type)
      options.callback ? this.callback = options.callback : null
      this.options.emit(options)
      this.isInit.emit(true)
   }

   removeItemFile(options: any) {
      console.log(options)
   }

   upload(file: File): Observable<HttpEvent<any>> {
      const formData: FormData = new FormData();
      formData.append('file', file);

      /*const req = new HttpRequest('POST', this.API+'/upload', formData, {
         reportProgress: true,
         //observe: "events",
         //responseType: 'json'
      });
      return this.http.request(req);*/

      return this.http.post(this.API+"/upload", formData, {
         reportProgress: true,
         observe: "events"
      })
   }

   async delete(file: any): Promise<any>{
      return await this.http.post<any>(this.API+"/delete", {file}).toPromise()
   }
}
