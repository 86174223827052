import { exit } from 'process';
import { FormControl } from '@angular/forms';

export class FormErrors{

   error: string[] = []
   constructor(){
      this.error['class']  = ''
   }

   validate(type: string, submitted: boolean, control: any, msg: string){
      this.error['msg']  = ''
      switch(type){
         case "required":
            if( control.dirty || control.touched || submitted && control.errors?.required){
               this.error['class'] = 'is-invalid'
               this.error['msg'] = msg != null ? msg : "Preencha este campo"
            }
            return control.valid ? "" : this.error['msg'];
         break;

         case "email":
            if( control.dirty || control.touched || submitted && control.errors?.required){
               this.error['msg'] = msg != null ? msg : "Preencha este campo"
               this.error['class'] = 'is-invalid'
            }

            if(control.dirty || submitted && control.errors?.email){
               this.error['msg'] =  msg != null ? msg : "Email inválido"
               this.error['class'] = 'is-invalid'
            }
            return control.valid ? "" : this.error['msg']
         break;

         case "password":
            if( control.dirty || control.touched || submitted && control.errors?.required){
               this.error['class'] = 'is-invalid'
               this.error['msg'] =  msg != null ? msg : "Preencha este campo"
            }

            if( control.dirty || control.touched || submitted && control.errors?.invalidPassword){
               this.error['class'] = 'is-invalid'
               this.error['msg'] =  msg != null ? msg : `A senha deve ter no mínimo 8 caracteres,
               1 letra maiúscula, 1 minúscula e números.`
            }
            return control.valid ? "" : this.error['msg']
         break;

         case "equals":
            if( control.dirty || control.touched || submitted && control.errors?.required){
               this.error['class'] = 'is-invalid'
               this.error['msg'] =  msg != null ? msg : "Preencha este campo"
            }

            if( control.dirty || control.touched || submitted && control.errors?.equals){
               this.error['class'] = 'is-invalid'
               this.error['msg'] =  msg != null ? msg : "Senhas diferentes"
            }
            return control.valid ? "" : this.error['msg']
         break;

         default:
            return ""
         break;
      }
   }

   classError(submit: boolean, control: any){
      let errorClass: string = ''
      if(submit && control.errors){
         errorClass = control.valid ? 'is-valid' : 'is-invalid'
      }

      if(control.touched && control.errors || control.dirty && control.errors){
         errorClass = control.valid ? 'is-valid' : 'is-invalid'
      }
      return errorClass
   }

   setClassError(control: FormControl){
      return {
         'is-valid': control.valid && control.touched,
         'is-invalid': control.invalid && control.touched
      }
   }


}
