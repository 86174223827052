import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import {HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'

import { AuthService } from '../components/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
   constructor(
      private authService: AuthService,
      private router: Router
    ) {}

   intercept(req: HttpRequest<any>, next: HttpHandler){
      const token = this.authService.getAuthorizationToken()
      const level =  sessionStorage.getItem("userLevel");

      let request: HttpRequest<any> = req

      if(!this.authService.isAuthenticated()){
        //this.router.navigate(['/auth'])
      }

      if(token){
         request = req.clone({
            headers: req.headers.set("Authorization", `${level} ${token}`)
         })
      }

      return next.handle(request).pipe(
         catchError(this.handleError)
      )

   }

   handleError(error: HttpErrorResponse){

      /*if(error.error instanceof ErrorEvent){
         console.error("Ocorreu um erro", error.error.message)
      }else{
         console.error(
            `Codigo de Erro: ${error.status}`+
            `Erro: ${JSON.stringify(error.error)}`
         )
      }*/
      return throwError("Ocorreu um erro tente novamente")
   }
}


export const authInterceptor = [
   { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
]
