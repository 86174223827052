<div>

   <div class="row no-gutters">
      <div class="col-12 col-sm-12 col-md-12">
         <div class="padding text-center">
            <h1 class="">Infelizmente não conseguimos <br>encontrar esta página.</h1>
            <p class="my-5 text-muted h4">-- 404 --</p>
         </div>
      </div>

   </div>


</div>
