import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable, EventEmitter } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private readonly API = `${environment.api}/orders`;

  isEdit = new EventEmitter<boolean>();
  isValidate = new EventEmitter<boolean>();

  constructor(private http: HttpClient) {}

  async select(): Promise<any> {
    return await this.http.get<any[]>(this.API).toPromise();
  }

  async getCurrentOrders(): Promise<any> {
    const order = { num: 0, total: 0 };
    const res = await this.http.get<any[]>(this.API).toPromise();
    var currentDate = new Date();

    res.forEach((enco, i) => {
      let orderDate = new Date(enco.created_at);
      if (!enco.status) {
        order.total += enco.totalCart;
        order.num++;
      }
    });

    return order;
  }

  //Seleciona por filtro
  async filter(values: any): Promise<any> {
    return await this.http.post<any>(this.API + '/edit', values).toPromise();
  }

  //visualiza a encomenda
  async edit(values: any): Promise<any> {
    return await this.http.post<any>(this.API + '/edit', values).toPromise();
  }

  //cria a encomenda
  async create(values: any): Promise<any> {
    return await this.http.post<any>(this.API + '/create', values).toPromise();
  }

  //Actualiza a encomenda
  async update(values: any): Promise<any> {
    return await this.http.put<any>(this.API + '/update', values).toPromise();
  }

  //valida o stock por parte do logista
  async validateStock(values: any): Promise<any> {
    return await this.http
      .post<any>(this.API + '/validate-stock', values)
      .toPromise();
  }

  //Valida a encomenda
  async validate(values: any): Promise<any> {
    return await this.http.post<any>(this.API + '/validate', values)
      .toPromise();
  }

  //Eleimina encomenda
  async delete(values: any): Promise<any> {
    return await this.http
      .post<any>(this.API + '/delete', {
        ids: values,
      })
      .toPromise();
  }
}
