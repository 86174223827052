<div id="aside" class="page-sidenav no-shrink bg-dark nav-dropdown fade"
 aria-hidden="true">
   <div class="sidenav h-100 modal-dialog bg-light"
   style="height: 100vh !important;">
      <div class="navbar">
         <a href="dashboard" class="navbar-brand">
            <span class="hidden-folded d-inline l-s-n-1x">Bazara Store </span>
         </a>
      </div>

      <div class="flex scrollable hover">
         <div class="nav-active-text-primary" data-nav>
            <ul class="nav routeLink" *ngIf="navMenu['mainShow']">
               <li class="nav-header hidden-folded">
               <span class="text-muted">Principal</span></li>

               <ng-container *ngFor="let item of navMenu['main']">
                  <li *ngIf="item.allowed">
                     <a routerLink="{{item.url}}" routerLinkActive="active">
                        <span class="nav-icon"><i class="{{item.icon}}"></i></span>
                        <span class="nav-text">{{item.title}}</span>
                     </a>
                  </li>
               </ng-container>
            </ul>

            <ul class="nav routeLink" *ngIf="navMenu['lojaShow']" >
               <li class="nav-header hidden-folded">
                  <span class="text-muted">Lojas</span>
               </li>

               <ng-container *ngFor="let item of navMenu['loja']">
                  <li *ngIf="item.allowed">
                     <a routerLink="{{item.url}}" routerLinkActive="active">
                        <span class="nav-icon">
                           <i class="{{item.icon}}"></i>
                        </span>
                        <span class="nav-text">{{item.title}}</span>
                     </a>
                  </li>
               </ng-container>
            </ul>

            <ul class="nav routeLink" *ngIf="navMenu['configShow']">
               <li class="nav-header hidden-folded">
                  <span class="text-muted">Configurações</span>
               </li>

               <ng-container *ngFor="let item of navMenu['config']">
                  <li *ngIf="item.allowed">
                     <a routerLink="{{item.url}}" routerLinkActive="active">
                        <span class="nav-icon"><i class="{{item.icon}}"></i></span>
                        <span class="nav-text">{{item.title}}</span>
                     </a>
                  </li>
               </ng-container>
            </ul>
         </div>
      </div>

      <div class="no-shrink" style="display: none;">
         <div class="p-3 d-flex align-items-center">
            <div class="text-sm hidden-folded text-muted">Trial: 35%</div>
            <div class="progress mx-2 flex" style="height:4px">
               <div class="progress-bar gd-success" style="width: 35%"></div>
            </div>
         </div>
      </div>
   </div>
</div>

