import { Component, Input, OnInit } from '@angular/core';

@Component({
   selector: 'app-spinners',
   templateUrl: './spinners.html',
   styleUrls: ['./spinners.css'],
})
export class SpinnersComponent implements OnInit {
   @Input() options: any

   constructor() {}

   ngOnInit(): void {}
}
