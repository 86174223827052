<div class="box-upload" *ngIf="showDialogUpload">
   <div class="box-content">
      <div class="box-left" style="display: none">
         <div class="box-header">
            <span class="title">Opções</span>
         </div>
      </div>

      <div class="box-right">
         <div class="box-header">
            <span class="title">Add Media</span>
            <a (click)="isInit.emit(false)" class="close" style="float: right; font-size: 25px">
               <span aria-hidden="true">&times;</span>
            </a>
         </div>

         <!-- GRID BODY -->
         <div class="box-body">
            <div class="dialog-tabs" type-tab="uploadTab">
               <div class="tabs-header" style="background-color: transparent;">
                  <a class="tab-nav isAddFiles" tab-target="isAddFiles">Carregar Ficheiros</a>
                  <a class="tab-nav isListFiles active" tab-target="isListFiles">Ficheiros
                     Carregados
                  </a>
               </div>

               <div class="tabs-body" id="isAddFiles">
                  <div class="row">
                     <div class="col-md-12 text-center" style="position: relative; margin-top: 20px;">
                        <article class="card-text">Arraste os seus arquivos aqui par ainicial o carregamento.</article>
                        <article>Ou</article><br>
                        <label class="is-btn is-btn-primary">
                           <input type="file" (change)="uploadFiles($event)" multiple style="display: none">
                           Selecionar Arquivos
                        </label><br><br>
                        <article>Dimensões: (282x193)</article>
                        <article>Tamanho máximo de por arquivo: 2MB</article>
                     </div>


                  </div>
               </div>

               <div class="col-md-12 tabs-body active" id="isListFiles">
                  <div class="row">
                     <div class="col-md-9 list-files border-right" style="height: 68vh; overflow: auto;">
                        <div class="d-flex justify-content-center" *ngIf="loader" style="margin-top:25%; ">
                           <div class="spinner-border text-primary" role="status">
                              <span class="sr-only">Loading...</span>
                           </div>
                        </div>



                        <div class="list-uploaded-files" *ngIf="!loader">
                           <ul>
                              <li class="file-item" *ngFor="let progress of progressFiles">
                                 <div class="file-inner"
                                 id="{{progress.id}}" (click)="editFile({'file': progress.file, 'id':progress.id})">
                                    <div class="progress" style="margin-top: 50%;" *ngIf="progress.percent < 100">
                                       <div class="progress-bar" role="progressbar"
                                       attr.aria-valuenow="{{ progress.percent }}"
                                       aria-valuemin="0"
                                       aria-valuemax="100"
                                       [ngStyle]="{ width: progress.percent + '%' }">
                                       {{ progress.percent }}%</div>
                                    </div>

                                    <ng-container *ngIf="progress.percent == 100">
                                       <img src="{{app.filesPath+progress.file.name}}"
                                       *ngIf="progress.file.type != '.pdf' && progress.file.type != '.xlsx'">

                                       <img src="{{app.IMG_PATH}}pdf-avatar.png"  *ngIf="progress.file.type == '.pdf'">
                                       <img src="{{app.IMG_PATH}}excel-avatar.png"  *ngIf="progress.file.type == '.xlsx'">
                                    </ng-container>
                                 </div>
                              </li>

                              <li class="file-item" *ngFor="let file of files; index as i">
                                 <div class="file-inner" id="{{i}}" (click)="editFile({'file': file, 'id':i})">
                                    <img src="{{file.path+file.name}}"
                                    *ngIf="file.type != '.pdf' && file.type != '.xlsx'">

                                    <img src="{{app.IMG_PATH}}pdf-avatar.png"  *ngIf="file.type == '.pdf'">
                                    <img src="{{app.IMG_PATH}}excel-avatar.png"  *ngIf="file.type == '.xlsx'">
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>

                     <!-- VISTA ARQUIVO SELECIONADO -->
                     <div class="col-md-3 preview-file">
                        <ng-container *ngIf="fileEdit.file && fileEdit.file.name">
                           <article>DETALHES DO ARQUIVO</article>
                           <img src="{{app.filesPath+fileEdit.file.name}}">

                           <article><b>{{fileEdit.file.label}}</b></article>
                           <a class="text-danger"
                           (click)="deleteFle(fileEdit)">
                              <i class="fa fa-trash"></i> Eliminar
                           </a>
                        </ng-container>
                     </div>
                  </div>


                  <div class="box-footer" style="background-color: #fff">
                     <div class="row">
                        <div class="col-sm-9">
                           <article><b>Ficheiros Selecionados: <span id="totalFiles">0</span></b></article>
                           <input type="hidden" id="fileSelecteds" value="[]">
                        </div>

                        <div class="col-sm-3 text-right">
                           <button type="button" class="is-btn is-btn-primary btnSetFiles"
                           [disabled]="selecteds.length <= 0" (click)="setFiles()">
                              Inserir Arquivos
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <!-- END GRID BODY -->
      </div>
   </div>
</div>

