import { UsersService } from './components/users/users.service';
import { PermissoesService } from './components/permissoes/permissoes.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './components/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.html',
  styleUrls: ['./app.css'],
})
export class AppComponent implements OnInit {
  title = 'invoice';
  isAuth: boolean;
  permissoes: any = null;
  user: any = {};
  userType = sessionStorage.getItem('userLevel');

  constructor(
    private authService: AuthService,
    private userService: UsersService,
    private permService: PermissoesService,
    private router: Router
  ) {
    console.log(router.url);
    this.isAuth = this.authService.isAuthenticated();
    if (this.isAuth && location.pathname == '/auth') {
      this.router.navigate(['dashboard']);
    }

    if (this.isAuth) {
      this.userService.authenticatedUser().then((res) => {
        this.user = res;
        this.user.type == 'Bearer' ? (this.permissoes = []) : false;
        //console.log(res)

        if (this.userType != 'Bearer') {
          this.permService.requiredList().then((perm) => {
            this.permissoes = perm;
            //console.log(perm)
          });
        }
      });
    }

    if (!this.isAuth) {
      //this.router.navigate(['auth']);
    }
  }

  ngOnInit() {
    //window.localStorage.removeItem("userToken")
    //window.localStorage.removeItem("userLevel")
    this.authService.signed.subscribe((event) => {
      this.isAuth = this.authService.isAuthenticated();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isAuth = this.authService.isAuthenticated();
      }
    });
  }
}
