import { SharedService } from 'src/app/shared/shared.service';
import { HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment.prod';
import { Injectable } from '@angular/core';

declare var $: any

@Injectable({ providedIn: 'root' })
export class AppService {
  private readonly API = `${environment.api}`;

  IMG_PATH: string = '../../../assets/uploads/';
  filesPath: string = 'http://localhost:4300/assets/cdn/files/';
  siteUrl: string = 'xawandi';
  typeUser: string = 'Logista';
  siteUploadPath: string = 'F:/Developer/web/bazara/src/assets/uploads';
  spinnerColor: string = '#06b2e6';

  constructor(
    private http: HttpClient
    ) {}

  async getNotifications() {
    return await this.http.get<any[]>(this.API + '/notifications').toPromise();
  }

  async addNotifications(values: any) {
    return await this.http
      .post<any[]>(this.API + '/notifications/register', values)
      .toPromise();
  }

  async deleteNotifications(values: any) {
    return await this.http
      .post<any[]>(this.API + '/notifications/delete', values)
      .toPromise();
  }
}
