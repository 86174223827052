import { AuthService } from './../../auth/auth.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.html',
  styleUrls: ['./nav.css'],
})
export class NavComponent implements OnInit {
  @Input() user: any
  @Input() permissoes: any
  navMenu: object = {};

  constructor(
    private authService: AuthService
  ) {
  }

  initMenu(perms: any) {
    var level = this.user.type

    this.navMenu = {
      mainShow: false,
      main: [
        {
          title: 'Dashboard', url: 'dashboard',
          icon: 'fa fa-tachometer-alt',
          allowed: true
        },
        {
          title: 'Categorias', url: 'categorias', icon: 'fa fa-paperclip',
          allowed: level == 'Bearer' ? true
            : perms.categorias && perms.categorias[0].label == 'visualizar'
            && perms.categorias[0].value
        },
        {
          title: 'Logistas', url: 'contas', icon: 'fa fa-users',
          allowed: level == 'Bearer' ? true
            : perms.contas && perms.contas[0].label == 'visualizar'
            && perms.contas[0].value
        }
      ],

      lojaShow: false,
      loja: [
        {
          title: 'Lojas', url: 'lojas', icon: 'fa fa-store',
          allowed: level == 'Bearer' ? true
            : perms.lojas && perms.lojas[0].label == 'visualizar' && perms.lojas[0].value
        },
        {
          title: 'Produtos', url: 'produtos', icon: 'fa fa-barcode',
          allowed: level == 'Bearer' ? true
            : perms.produtos && perms.produtos[0].label == 'visualizar'
            && perms.produtos[0].value
        },
        {
          title: 'Encomendas', url: 'encomendas', icon: 'fa fa-briefcase',
          allowed: level == 'Bearer' ? true
            : perms.encomendas && perms.encomendas[0].label == 'visualizar'
            && perms.encomendas[0].value
        },
        {
          title: 'Entregas', url: 'delivery', icon: 'fa fa-truck',
          allowed: level == 'Bearer' ? true
            : perms.delivery && perms.delivery[0].label == 'visualizar'
            && perms.delivery[0].value
        },
        {
          title: 'Relatórios', url: 'relatorios', icon: 'fa fa-chart-bar',
          allowed: level == 'Bearer' ? true
            : perms.relatorios && perms.relatorios[0].label == 'visualizar'
            && perms.relatorios[0].value
        },
      ],

      configShow: false,
      config: [
        {
          title: 'Gestores', url: 'users', icon: 'fa fa-user',
          allowed: level == 'Bearer' ? true
            : perms.utilizadores && perms.utilizadores[0].label == 'visualizar'
            && perms.utilizadores[0].value
        },
        {
          title: 'Definições', url: 'settings', icon: 'fa fa-cog',
          allowed: level == 'Bearer' ? true
            : perms.definicoes && perms.definicoes[0].label == 'visualizar'
            && perms.definicoes[0].value
        },
        {
          title: 'Permissões', url: 'permissoes', icon: 'fa fa-lock',
          allowed: level == 'Bearer' ? true
            : perms.permissoes && perms.permissoes[0].label == 'visualizar'
            && perms.permissoes[0].value
        },
      ]
    }

    this.navMenu['main'].forEach(elem => {
      if (elem.allowed) { this.navMenu['mainShow'] = true }
    })
    this.navMenu['loja'].forEach(elem => {
      if (elem.allowed) { this.navMenu['lojaShow'] = true }
    })
    this.navMenu['config'].forEach(elem => {
      if (elem.allowed) { this.navMenu['configShow'] = true }
    })
  }

  ngOnInit(): void {
    if (this.user.type == "Bearer") {
      this.initMenu(null)
    } else {
      this.initMenu(this.permissoes)
    }
  }
}
