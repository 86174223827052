
import { Injectable } from '@angular/core';
import { ValidatorFn, FormGroup, AbstractControl, Form } from '@angular/forms';
import { FormErrors } from './form.errors';

@Injectable({
   providedIn: 'root'
})
export class FormValidationService extends FormErrors {

   status: boolean = true

   constructor(){
      super()

   }

   passwordValidator(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } => {
         if (!control.value) {
            return null;
         }
         const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
         const valid = regex.test(control.value);
         return valid ? null : { invalidPassword: true };
      };
   }

   equals(value1: string, value2: string) {
      return (formGroup: FormGroup) => {
         const control1 = formGroup.controls[value1];
         const control2 = formGroup.controls[value2];

         if (!control1 || !control2) {
            return null;
         }

         if (control2.errors && !control2.errors.equals) {
            return null;
         }

         if (control1.value !== control2.value) {
            control2.setErrors({ equals: true });
         } else {
            control2.setErrors(null);
         }
      }
   }

   MatchPassword(password: string, confirmPassword: string) {
      return (formGroup: FormGroup) => {
         const passwordControl = formGroup.controls[password];
         const confirmPasswordControl = formGroup.controls[confirmPassword];

         if (!passwordControl || !confirmPasswordControl) {
            return null;
         }

         if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
            return null;
         }

         if (passwordControl.value !== confirmPasswordControl.value) {
            confirmPasswordControl.setErrors({ passwordMismatch: true });
         } else {
            confirmPasswordControl.setErrors(null);
         }
      }
   }

   /*userNameValidator(userControl: AbstractControl) {
      return new Promise(resolve => {
         setTimeout(() => {
            if (this.validateUserName(userControl.value)) {
               resolve({ userNameNotAvailable: true });
            } else {
               resolve(null);
            }
         }, 1000);
      });
   }

   validateUserName(userName: string) {
      const UserList = ['ankit', 'admin', 'user', 'superuser'];
      return (UserList.indexOf(userName) > -1);
   }*/
}
