import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-access',
  templateUrl: './not-access.html',
  styleUrls: ['./not-access.css']
})
export class NotAccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
