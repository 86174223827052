<div id="conten" class="flex page-scroll" style="background-color: #f1f1f1a3;" >
   <div class="page-content page-containe no-border" id="page-content">
      <div class="padding" >
         <div class="row row-s" >
            <div class="col-lg-8" *ngIf="dashboard['defaultShow']">
               <div class="row row-sm">
                  <ng-container *ngFor="let item of dashboard['default']">
                     <div class="col-4 d-flex" *ngIf="item.allowed === true ">
                        <div class="card flex" data-sr-id="3" routerLink="/{{item.url}}"
                        style="visibility: visible; cursor: pointer; ">
                           <div class="card-body">
                              <div class="d-flex align-items-lef" >
                                 <small class="flex">{{item.title}}:
                                    <article>{{item.value}}</article>
                                 </small>
                                 <div class="avatar w-56 m-2 no-shadow" ngClass="{{item.bg}}">
                                    <i ngClass="{{item.icon}}"></i>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </ng-container>
               </div>
            </div>

            <!--<div class="col-lg-5">
               <div class="row row-sm">
                  <ng-container *ngFor="let item of dashboard['orders']">
                     <div class="col-md-6" *ngIf="item.allowed === true ">
                        <div class="card" data-sr-id="5" routerLink="/{{item.url}}"
                        style="cursor:pointer; visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                           <div class="card-body text-center">
                              <small class="text-muted">Novas encomendas</small>
                              <div class="pt-3">
                                 <div style="height: 120px" class="pos-rlt">
                                    <div class="chartjs-size-monitor" style="position: absolute; left: 0px; top: 0px;
                                    right: 0px; bottom: 0px; overflow: hidden; pointer-events: none;
                                    visibility: hidden; z-index: -1;">
                                       <div class="chartjs-size-monitor-expand" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div></div><div class="chartjs-size-monitor-shrink" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:200%;height:200%;left:0; top:0"></div></div></div>
                                       <div class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center">
                                          <div>
                                             <div class="text-highlight text-md">
                                                <span>{{item.value}}</span>
                                             </div>
                                             <small class="text-muted"></small>
                                          </div>
                                       </div>
                                       <canvas id="chart-pie-1" width="493" height="120"
                                    class="chartjs-render-monitor" style="display:
                                    block; width: 493px; height: 120px;"></canvas>

                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </ng-container>

                  <div class="col-md-6" style="display: none;">
                     <div class="card" data-sr-id="5" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                        <div class="card-body text-center">
                           <small class="text-muted">Total Encomendas</small>
                           <div class="pt-3">
                              <div style="height: 120px" class="pos-rlt"><div class="chartjs-size-monitor" style="position: absolute; left: 0px; top: 0px; right: 0px; bottom: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;"><div class="chartjs-size-monitor-expand" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div></div><div class="chartjs-size-monitor-shrink" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:200%;height:200%;left:0; top:0"></div></div></div>
                                 <div class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center">
                                    <div>
                                       <div class="text-highlight text-md">
                                          <span>{{orders.total |currency:'':''}}</span>
                                       </div>
                                       <small class="text-muted"> Akz</small>
                                    </div>
                                 </div><canvas id="chart-pie-1" width="493" height="120" class="chartjs-render-monitor" style="display: block; width: 493px; height: 120px;"></canvas>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>-->






            <ng-container *ngIf="visible" >
               <div class="col-md-4 d-flex">
                  <div class="card flex" data-sr-id="10" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                     <div class="card-body">
                        <div class="d-flex align-items-center text-hover-success">
                           <div class="avatar w-56 m-2 no-shadow gd-success"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trending-up"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"></polyline></svg>
                           </div>
                           <div class="px-4 flex">
                              <div>Weekly top sell</div>
                              <div class="text-success mt-2">+ 2.50%</div>
                           </div><a href="#" class="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></a>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-md-8 d-flex">
                  <div class="card flex" data-sr-id="11" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                     <div class="card-body">
                        <div class="row row-sm">
                           <div class="col-sm-6">
                              <div class="mb-2"><small class="text-muted">Task statistics</small></div>
                              <div class="row row-sm">
                                 <div class="col-4">
                                    <div class="text-highlight text-md">52</div><small>Tasks</small>
                                 </div>
                                 <div class="col-4">
                                    <div class="text-danger text-md">+15</div><small>Added</small>
                                 </div>
                                 <div class="col-4">
                                    <div class="text-md">45.5%</div><small>Remain</small>
                                 </div>
                              </div>
                           </div>
                           <div class="col-12 col-sm-6">
                              <div class="mb-2 mt-2 mt-sm-0"><small class="text-muted">This week</small></div>
                              <div>Task completion</div>
                              <div class="progress no-bg mt-2 align-items-center circle" style="height:6px">
                                 <div class="progress-bar circle gd-danger" style="width: 65%"></div><span class="mx-2">65%</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-md-4">
                  <div class="row row-sm">
                     <div class="col-6">
                        <div class="card" data-sr-id="12" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                           <div class="card-body">
                              <div class="pos-rlt" style="height: 78px"><div class="chartjs-size-monitor" style="position: absolute; left: 0px; top: 0px; right: 0px; bottom: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;"><div class="chartjs-size-monitor-expand" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div></div><div class="chartjs-size-monitor-shrink" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:200%;height:200%;left:0; top:0"></div></div></div>
                                 <div class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center">
                                    <small>35%</small></div><canvas id="chart-pie-2" width="219" height="78" class="chartjs-render-monitor" style="display: block; width: 219px; height: 78px;"></canvas>
                              </div>
                              <div class="px-3 pt-3 text-center"><small>Weekly</small></div>
                           </div>
                        </div>
                     </div>
                     <div class="col-6">
                        <div class="card" data-sr-id="13" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                           <div class="card-body">
                              <div class="pos-rlt" style="height: 78px"><div class="chartjs-size-monitor" style="position: absolute; left: 0px; top: 0px; right: 0px; bottom: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;"><div class="chartjs-size-monitor-expand" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div></div><div class="chartjs-size-monitor-shrink" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:200%;height:200%;left:0; top:0"></div></div></div>
                                 <div class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center">
                                    <small>25%</small></div><canvas id="chart-pie-3" width="219" height="78" class="chartjs-render-monitor" style="display: block; width: 219px; height: 78px;"></canvas>
                              </div>
                              <div class="px-3 pt-3 text-center"><small>Monthly</small></div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12">
                        <div class="card pb-3" data-sr-id="14" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                           <div class="p-3-4">
                              <div class="d-flex">
                                 <div>
                                    <div>Upcoming tasks</div><small class="text-muted">Active: 9</small>
                                 </div><span class="flex"></span>
                                 <div>
                                    <div class="btn-group-toggle" data-toggle="buttons"><label class="btn"><input type="radio" name="options"> 1h</label><label class="btn active"><input type="radio" name="options"> 1d</label></div>
                                 </div>
                              </div>
                           </div>
                           <div class="list list-row">
                              <div class="list-item" data-id="16" data-sr-id="15" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                                 <div><label class="ui-check m-0 ui-check-rounded ui-check-md"><input type="checkbox" name="id" value="16"> <i></i></label></div>
                                 <div class="flex"><a href="#" class="item-title text-color h-1x">AI Could
                                       Uber</a>
                                    <div class="item-except text-muted text-sm h-1x">When it comes to artificial
                                       intelligence, little things can add up in big ways</div>
                                 </div>
                                 <div>
                                    <div class="item-action dropdown"><a href="#" data-toggle="dropdown" class="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg></a>
                                       <div class="dropdown-menu dropdown-menu-right bg-black" role="menu"><a class="dropdown-item" href="#">See detail </a><a class="dropdown-item download">Download </a><a class="dropdown-item edit">Edit</a>
                                          <div class="dropdown-divider"></div><a class="dropdown-item trash">Delete item</a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="list-item" data-id="9" data-sr-id="16" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                                 <div><label class="ui-check m-0 ui-check-rounded ui-check-md"><input type="checkbox" name="id" value="9"> <i></i></label></div>
                                 <div class="flex"><a href="#" class="item-title text-color h-1x">Web App develop
                                       tutorial</a>
                                    <div class="item-except text-muted text-sm h-1x">Build a progressive web app
                                       using jQuery</div>
                                 </div>
                                 <div>
                                    <div class="item-action dropdown"><a href="#" data-toggle="dropdown" class="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg></a>
                                       <div class="dropdown-menu dropdown-menu-right bg-black" role="menu"><a class="dropdown-item" href="#">See detail </a><a class="dropdown-item download">Download </a><a class="dropdown-item edit">Edit</a>
                                          <div class="dropdown-divider"></div><a class="dropdown-item trash">Delete item</a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="list-item" data-id="8" data-sr-id="17" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                                 <div><label class="ui-check m-0 ui-check-rounded ui-check-md"><input type="checkbox" name="id" value="8"> <i></i></label></div>
                                 <div class="flex"><a href="#" class="item-title text-color h-1x">DEV DAY 2018</a>
                                    <div class="item-except text-muted text-sm h-1x">Working on a research piece
                                       on the history of vocational schools in America.</div>
                                 </div>
                                 <div>
                                    <div class="item-action dropdown"><a href="#" data-toggle="dropdown" class="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg></a>
                                       <div class="dropdown-menu dropdown-menu-right bg-black" role="menu"><a class="dropdown-item" href="#">See detail </a><a class="dropdown-item download">Download </a><a class="dropdown-item edit">Edit</a>
                                          <div class="dropdown-divider"></div><a class="dropdown-item trash">Delete item</a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-md-8">
                  <div class="card" data-sr-id="18" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                     <div class="p-3-4">
                        <div class="d-flex mb-3">
                           <div>
                              <div>Summary</div><small class="text-muted">All mentions: 12,340</small>
                           </div><span class="flex"></span>
                           <div>
                              <div class="btn-group btn-group-toggle" id="btn_l_4" data-toggle="buttons"><label class="btn active"><input type="radio" name="options"> Month</label><label class="btn"><input type="radio" name="options"> Week</label></div>
                           </div>
                        </div>
                        <div class="p-2" style="height: 364px"><div class="chartjs-size-monitor" style="position: absolute; left: 0px; top: 0px; right: 0px; bottom: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;"><div class="chartjs-size-monitor-expand" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div></div><div class="chartjs-size-monitor-shrink" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:200%;height:200%;left:0; top:0"></div></div></div><canvas id="chart-line-4" width="477" height="348" class="chartjs-render-monitor" style="display: block; width: 477px; height: 348px;"></canvas></div>
                     </div>
                  </div>
               </div>

               <div class="col-md-7">
                  <div class="card" data-sr-id="19" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                     <div class="p-3-4">
                        <div class="d-flex">
                           <div>
                              <div>Top groups</div><small class="text-muted">Total: 230</small>
                           </div><span class="flex"></span>
                           <div><a href="#" class="btn btn-sm btn-white">More</a></div>
                        </div>
                     </div>
                     <table class="table table-theme v-middle m-0">
                        <tbody>
                           <tr class="" data-id="16">
                              <td style="min-width:30px;text-align:center">0</td>
                              <td>
                                 <div class="avatar-group"><a href="#" class="avatar ajax w-32" data-toggle="tooltip" title="" data-pjax-state="" data-original-title="Urna"><img src="../assets/img/a15.jpg" alt="."> </a><a href="#" class="avatar ajax w-32" data-toggle="tooltip" title="" data-pjax-state="" data-original-title="Eu"><img src="../assets/img/a11.jpg" alt="."> </a><a href="#" class="avatar ajax w-32" data-toggle="tooltip" title="" data-pjax-state="" data-original-title="Odio"><img src="../assets/img/a13.jpg" alt="."></a></div>
                              </td>
                              <td class="flex"><a href="page.invoice.detail.html" class="item-company ajax h-1x" data-pjax-state="">Microsoft</a>
                                 <div class="item-mail text-muted h-1x d-none d-sm-block">
                                    frances-stewart@microsoft.com</div>
                              </td>
                              <td><span class="item-amount d-none d-sm-block text-sm">200</span></td>
                              <td>
                                 <div class="item-action dropdown"><a href="#" data-toggle="dropdown" class="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg></a>
                                    <div class="dropdown-menu dropdown-menu-right bg-black" role="menu"><a class="dropdown-item" href="#">See detail </a><a class="dropdown-item download">Download </a><a class="dropdown-item edit">Edit</a>
                                       <div class="dropdown-divider"></div><a class="dropdown-item trash">Delete
                                          item</a>
                                    </div>
                                 </div>
                              </td>
                           </tr>
                           <tr class="" data-id="4">
                              <td style="min-width:30px;text-align:center">1</td>
                              <td>
                                 <div class="avatar-group"><a href="#" class="avatar ajax w-32" data-toggle="tooltip" title="" data-pjax-state="" data-original-title="Libero"><img src="../assets/img/a3.jpg" alt="."> </a><a href="#" class="avatar ajax w-32" data-toggle="tooltip" title="" data-pjax-state="" data-original-title="Consequat"><img src="../assets/img/a15.jpg" alt="."></a></div>
                              </td>
                              <td class="flex"><a href="page.invoice.detail.html" class="item-company ajax h-1x" data-pjax-state="">GE</a>
                                 <div class="item-mail text-muted h-1x d-none d-sm-block">billy-johnston@ge.com
                                 </div>
                              </td>
                              <td><span class="item-amount d-none d-sm-block text-sm">20</span></td>
                              <td>
                                 <div class="item-action dropdown"><a href="#" data-toggle="dropdown" class="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg></a>
                                    <div class="dropdown-menu dropdown-menu-right bg-black" role="menu"><a class="dropdown-item" href="#">See detail </a><a class="dropdown-item download">Download </a><a class="dropdown-item edit">Edit</a>
                                       <div class="dropdown-divider"></div><a class="dropdown-item trash">Delete
                                          item</a>
                                    </div>
                                 </div>
                              </td>
                           </tr>
                           <tr class="" data-id="17">
                              <td style="min-width:30px;text-align:center">2</td>
                              <td>
                                 <div class="avatar-group"><a href="#" class="avatar ajax w-32" data-toggle="tooltip" title="" data-pjax-state="" data-original-title="Adipiscing"><img src="../assets/img/a6.jpg" alt="."></a></div>
                              </td>
                              <td class="flex"><a href="page.invoice.detail.html" class="item-company ajax h-1x" data-pjax-state="">Alibaba</a>
                                 <div class="item-mail text-muted h-1x d-none d-sm-block">alan-mendez@alibaba.com
                                 </div>
                              </td>
                              <td><span class="item-amount d-none d-sm-block text-sm">320</span></td>
                              <td>
                                 <div class="item-action dropdown"><a href="#" data-toggle="dropdown" class="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg></a>
                                    <div class="dropdown-menu dropdown-menu-right bg-black" role="menu"><a class="dropdown-item" href="#">See detail </a><a class="dropdown-item download">Download </a><a class="dropdown-item edit">Edit</a>
                                       <div class="dropdown-divider"></div><a class="dropdown-item trash">Delete
                                          item</a>
                                    </div>
                                 </div>
                              </td>
                           </tr>
                           <tr class="" data-id="19">
                              <td style="min-width:30px;text-align:center">3</td>
                              <td>
                                 <div class="avatar-group"><a href="#" class="avatar ajax w-32" data-toggle="tooltip" title="" data-pjax-state="" data-original-title="Erat"><img src="../assets/img/a15.jpg" alt="."></a></div>
                              </td>
                              <td class="flex"><a href="page.invoice.detail.html" class="item-company ajax h-1x" data-pjax-state="">AI</a>
                                 <div class="item-mail text-muted h-1x d-none d-sm-block">tiffany-baker@ai.com
                                 </div>
                              </td>
                              <td><span class="item-amount d-none d-sm-block text-sm">320</span></td>
                              <td>
                                 <div class="item-action dropdown"><a href="#" data-toggle="dropdown" class="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg></a>
                                    <div class="dropdown-menu dropdown-menu-right bg-black" role="menu"><a class="dropdown-item" href="#">See detail </a><a class="dropdown-item download">Download </a><a class="dropdown-item edit">Edit</a>
                                       <div class="dropdown-divider"></div><a class="dropdown-item trash">Delete
                                          item</a>
                                    </div>
                                 </div>
                              </td>
                           </tr>
                           <tr class="" data-id="15">
                              <td style="min-width:30px;text-align:center">4</td>
                              <td>
                                 <div class="avatar-group"><a href="#" class="avatar ajax w-32" data-toggle="tooltip" title="" data-pjax-state="" data-original-title="Lobortis"><img src="../assets/img/a6.jpg" alt="."></a></div>
                              </td>
                              <td class="flex"><a href="page.invoice.detail.html" class="item-company ajax h-1x" data-pjax-state="">Google</a>
                                 <div class="item-mail text-muted h-1x d-none d-sm-block">
                                    jean-armstrong@google.com</div>
                              </td>
                              <td><span class="item-amount d-none d-sm-block text-sm">240</span></td>
                              <td>
                                 <div class="item-action dropdown"><a href="#" data-toggle="dropdown" class="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg></a>
                                    <div class="dropdown-menu dropdown-menu-right bg-black" role="menu"><a class="dropdown-item" href="#">See detail </a><a class="dropdown-item download">Download </a><a class="dropdown-item edit">Edit</a>
                                       <div class="dropdown-divider"></div><a class="dropdown-item trash">Delete
                                          item</a>
                                    </div>
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>

               <div class="col-md-5 d-flex">
                  <div class="card flex" data-sr-id="20" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                     <div class="p-3-4">
                        <div class="d-flex">
                           <div>
                              <div>Courses</div><small class="text-muted">on-going: 12</small>
                           </div><span class="flex"></span>
                           <div><a href="#" class="p-1 text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg></a>
                           </div>
                        </div>
                     </div>
                     <div class="list list-row">
                        <div class="list-item" data-id="17" data-sr-id="21" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                           <div><span class="badge badge-circle text-warning"></span></div>
                           <div class="flex"><a href="#" class="item-title text-color h-1x">AI Could Uber</a>
                           </div>
                           <div><span class="item-amount d-none d-sm-block text-sm text-muted">53</span></div>
                        </div>
                        <div class="list-item" data-id="9" data-sr-id="22" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                           <div><span class="badge badge-circle text-info"></span></div>
                           <div class="flex"><a href="#" class="item-title text-color h-1x">Web App develop
                                 tutorial</a></div>
                           <div><span class="item-amount d-none d-sm-block text-sm text-muted">20</span></div>
                        </div>
                        <div class="list-item" data-id="13" data-sr-id="23" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                           <div><span class="badge badge-circle text-primary"></span></div>
                           <div class="flex"><a href="#" class="item-title text-color h-1x">Feed Reader</a></div>
                           <div><span class="item-amount d-none d-sm-block text-sm text-muted">20</span></div>
                        </div>
                        <div class="list-item" data-id="3" data-sr-id="24" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                           <div><span class="badge badge-circle text-primary"></span></div>
                           <div class="flex"><a href="#" class="item-title text-color h-1x">Html5 dashboard
                                 conference</a></div>
                           <div><span class="item-amount d-none d-sm-block text-sm text-muted">50</span></div>
                        </div>
                        <div class="list-item" data-id="2" data-sr-id="25" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                           <div><span class="badge badge-circle text-primary"></span></div>
                           <div class="flex"><a href="#" class="item-title text-color h-1x">Data analytics
                                 application</a></div>
                           <div><span class="item-amount d-none d-sm-block text-sm text-muted">4</span></div>
                        </div>
                        <div class="list-item" data-id="4" data-sr-id="26" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                           <div><span class="badge badge-circle text-success"></span></div>
                           <div class="flex"><a href="#" class="item-title text-color h-1x">Open source project
                                 public release</a></div>
                           <div><span class="item-amount d-none d-sm-block text-sm text-muted">7</span></div>
                        </div>
                        <div class="list-item" data-id="1" data-sr-id="27" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                           <div><span class="badge badge-circle text-primary"></span></div>
                           <div class="flex"><a href="#" class="item-title text-color h-1x">WordPress dashboard
                                 redesign</a></div>
                           <div><span class="item-amount d-none d-sm-block text-sm text-muted">5</span></div>
                        </div>
                     </div>
                  </div>
               </div>
            </ng-container>
         </div>
      </div>
   </div>
</div>
