import { map, catchError } from 'rxjs/operators';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, EventEmitter } from '@angular/core';

import { AppService } from './../../app.service';
import { SharedService } from 'src/app/shared/shared.service';
import { UploadService } from './upload.service';
import { Observable, throwError } from 'rxjs';

declare var $: any;

@Component({
   selector: 'app-upload',
   templateUrl: './upload.html',
   styleUrls: ['./upload.css'],
})
export class UploadComponent implements OnInit {
   @Input() isInit = new EventEmitter<boolean>();
   @Input() options = new EventEmitter<boolean>();
   @Input() callback: (files: any, selected: boolean) => void;

   loader: boolean = true;
   progress: number;
   showDialogUpload: boolean = false;
   uploadOptions: any = {};
   files: any[];
   fileEdit: any;
   selecteds: any[] = [];

   //Variaveis de uploads
   selectedFiles: FileList;
   progressFiles = [];
   progressInfos = [];
   message = '';
   fileInfos: Observable<any>;

   constructor(
      public app: AppService,
      public shared: SharedService,
      private uploadService: UploadService
   ) {
      this.fileEdit = {};
   }

   editFile(data: any) {
      const totalFiles = document.getElementById('totalFiles');
      const fileSeleteds = <any>document.getElementById('fileSelecteds');

      if (this.uploadOptions.type == 'multiple') {
         this.selectItem(data);
      }

      if (this.uploadOptions.type == 'single') {
         fileSeleteds.value = '[]';
         this.selecteds = [];
         $('.list-uploaded-files .col-lg-2.active').removeClass('active');
         this.selectItem(data);
      }
   }

   selectItem(data: any) {
      let totalFiles = document.getElementById('totalFiles');
      let fileSeleteds = <any>document.getElementById('fileSelecteds');
      let item = document.getElementById(data.id);

      this.selecteds = JSON.parse(fileSeleteds.value);
      this.fileEdit = data;

      const file = {
         name: this.fileEdit.file.name,
         type: this.fileEdit.file.type,
         label: this.fileEdit.file.label,
         path: this.fileEdit.file.path,
      };

      item.classList.toggle('active');
      if (item.classList.contains('active')) {
         this.selecteds.push({ file: file });
      } else {
         this.selecteds.splice(this.selecteds.indexOf(file), 1);
      }

      fileSeleteds.value = JSON.stringify(this.selecteds);
      totalFiles.textContent = this.selecteds.length.toString();
   }

   setFiles() {
      let fileSeleteds = <any>document.getElementById('fileSelecteds');
      this.selecteds = JSON.parse(fileSeleteds.value);

      this.shared.btnSpinner(true, { btn: '.btnSetFiles' });
      setTimeout(() => {
         this.shared.btnSpinner(false, { btn: '.btnSetFiles' });
         this.callback(this.selecteds, true);
         this.showDialogUpload = false;
      }, 500);
   }

   uploadFiles(event) {
      const allFiles = document.querySelectorAll(".file-item")

      this.shared.initTabs({ type: 'single', tab: 'isListFiles' });
      let files: FileList = event.target.files;

      for (let i = 0; i < files.length; i++) {
         this.progressFiles[i] = { percent: 0, id:0, file: {} };
         //this.upload(i, this.selectedFiles[i]);

         this.uploadService.upload(files[i]).subscribe(
            (event) => {
               if (event.type === HttpEventType.UploadProgress) {
                  this.progressFiles[i].id = allFiles.length + i
                  this.progressFiles[i].percent = Math.round(
                     (100 * event.loaded) / event.total
                  );
               } else if (event instanceof HttpResponse) {
                  this.progressFiles[i].file = event.body
               }
            },
            (err) => {
               //this.progressInfos[idx].value = 0;
               //this.message = 'Could not upload the file:' + file.name;
            }
         );
      }
      console.log(this.progressFiles);
   }

   async deleteFle(item: any) {
      if (confirm('Desejas relmente eliminar este ficheiro?')) {
         await this.uploadService.delete(item.file.name).then((res) => {
            let fileRemove = document.getElementById(item.id);
            let totalFiles = document.getElementById('totalFiles');
            let fileSeleteds = <any>document.getElementById('fileSelecteds');

            this.fileEdit = {};
            fileRemove.remove();
            totalFiles.innerHTML = '0';
            fileSeleteds.value = '[]';
            this.isInit.emit(true);
         });
      }
   }

   ngOnInit(): void {
      this.isInit.subscribe((event) => {
         this.showDialogUpload = event;
         if (event) {
            this.uploadService.getFiles().then((res) => {
               this.files = res;
               this.loader = false;
            });
            setTimeout(() => {
               this.shared.initTabs({ type: 'multiple' });
            }, 500);
         }
      });

      this.options.subscribe((event) => {
         this.uploadOptions = event;
      });
   }
}
