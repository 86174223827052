<div ngClass="spinner-{{options.type}}" *ngIf="options.type == 'fixed'">
   <div class="spinner-content text-center" [ngClass]="{'no-spinner': options.noSpinner}">
      <div class="lds-ring {{options.color}}">
         <div></div><div></div><div></div><div></div>
      </div>
   </div>
</div>

<ng-container *ngIf="options.type == 'spinner2'">
   <div class="loader" [ngStyle]="{color: options.color }">Loading...</div>
</ng-container>
