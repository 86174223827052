import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { AppService } from '../app.service';
import * as printJs from 'print-js';

import { LoadingBarService } from '@ngx-loading-bar/core';

declare var alertify: any;
declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private readonly API = `${environment.api}`;
  submitForm: boolean = false

  constructor(
    private app: AppService,
    private router: Router,
    private loadingBar: LoadingBarService,
    private http: HttpClient
  ) {}

  alertPush(option: any = {}) {
    alertify.set('notifier', 'position', option.position);
    alertify.notify(option.msg, option.type, option.delay);
  }

  alertMsg(option: any = {}) {
    var div = document.querySelector(option.div);
    div.innerHTML =
      `
      <div class="alert alert-` +
      option.type +
      `" role="alert" style="display: none">
         ` +
      option.msg +
      `
      </div>
      `;
    $('.alert').fadeIn('slow', function () {
      $(this).delay(option.delay).fadeOut('slow');
    });
  }

  bsModalClose(bsModal) {
    $(bsModal).modal('hide');
  }

  bsModal(options: any) {
    $(options.modal).modal(options.action);
  }

  closeDialog(dialog) {
    document.querySelector(dialog).style.display = 'none';
  }

  spinner(visible: boolean, options: any) {
    let spinner = <HTMLElement>document.querySelector('.app-spinner');
    spinner.innerHTML = `
      <div class="d-flex justify-content-center">
         <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
         </div>
      </div>
      `;
    spinner.style.display = visible ? 'block' : 'none';
  }

  btnSpinner(status, options: any) {
    this.loadingBar.start();
    var btn = <any>document.querySelector(options.btn);

    if (status) {
      btn.setAttribute('disabled', true);
      let noSpinner = `<span class="btnSpinner spinner-border
      spinner-border-sm mr-2" role="status" aria-hidden="true"></span>`;

      const img =
        options.color && options.color == 'dark'
          ? 'load-black.svg'
          : 'load-white.svg';
      btn.insertAdjacentHTML(
        'afterbegin',
        !options.hasOwnProperty('noSpinner') ? noSpinner : ''
      );
    }

    if (!status) {
      this.loadingBar.stop();

      btn.removeAttribute('disabled');
      if (!options.hasOwnProperty('noSpinner')) {
        let spinner = <any>document.querySelector('.btnSpinner');
        spinner.remove();
      }
    }
  }

  classRemove(ids: [], elem: string) {
    var actionOptions = <any>document.getElementById('actionOptions');
    var actionButton = <any>document.getElementById('actionButton');
    var optionbar = <any>document.getElementById('optionBar');

    ids.forEach((id) => {
      $(elem + id).addClass('is-delete');
      $(elem + id)
        .delay(500)
        .fadeOut('slow', function () {
          $(elem + id).remove();
          optionbar.style.display = 'none';
          //actionOptions.disabled = true
          //actionButton.disabled = true
        });
    });
  }

  quantity(type: string, control: any) {
    if (type == 'inc') {
      let inc = control.value;
      inc++;
      control.setValue(inc);
    }

    if (type == 'dec') {
      let dec = control.value;
      dec--;
      control.setValue(control.value <= 1 ? 1 : dec);
    }
  }

  quantityInput(type: string, inputElement: any) {
    const input = document.querySelector(inputElement);

    if (type == 'inc') {
      let inc = input.value;
      inc++;
      input.value = inc;
      input.setAttribute('data-value', inc);
    }

    if (type == 'dec') {
      let dec = input.value;
      dec--;
      input.value = input.value <= 1 ? 1 : dec;
      input.setAttribute('data-value', input.value);
    }
  }

  optionBarClose() {
    var inputCheckAll = <any>document.getElementById('inputCheckAll');
    inputCheckAll.checked = false;
    this.inputCheck('all');
  }

  inputCheck(type) {
    var inputCheckAll = <any>document.getElementById('inputCheckAll');
    var inputItems = <any>document.querySelectorAll('.inputCheckItems input');
    var inputValue = <any>document.getElementById('inputCheckValues');
    var actionOptions = <any>document.getElementById('actionOptions');
    var actionButton = <any>document.getElementById('actionButton');
    var optionbar = <any>document.getElementById('optionBar');

    switch (type) {
      case 'all':
        var itemsPush = JSON.parse(inputValue.value);
        itemsPush = [];

        inputItems.forEach((input) => {
          if (inputCheckAll.checked) {
            input.checked = true;
            itemsPush.push(input.value);
            optionbar.style.display = 'block';
          } else {
            input.checked = false;
            itemsPush = [];
            optionbar.style.display = 'none';
          }
        });

        //actionOptions.disabled = itemsPush.length > 0 ? false : true;
        //actionButton.disabled = itemsPush.length > 0 ? false : true;
        inputValue.value = JSON.stringify(itemsPush);
        break;

      default:
        var itemsPush = JSON.parse(inputValue.value);
        var input = <any>document.querySelector('.inputCheck' + type);

        if (input.checked) {
          input.checked = true;
          itemsPush.push(input.getAttribute('value'));
        } else {
          input.checked = false;
          itemsPush.splice(itemsPush.indexOf(type), 1);
        }

        inputValue.value = JSON.stringify(itemsPush);
        optionbar.style.display = itemsPush.length > 0 ? 'block' : 'none';
        //actionOptions.disabled = itemsPush.length > 0 ? false : true;
        //actionButton.disabled = itemsPush.length > 0 ? false : true;
        //console.log(inputValue.value)
        break;
    }
  }

  initTabs(options: any) {
    let tabsNav = document.querySelectorAll('.tabs-header .tab-nav');
    let tabsBody = document.querySelectorAll('.tabs-body');
    if (options.type == 'multiple') {
      tabsNav.forEach((nav, index) => {
        nav.addEventListener('click', () => {
          let activeTab = <any>(
            document.querySelector('.tabs-header .tab-nav.active')
          );
          let currentTab = <any>nav.getAttribute('tab-target');
          let activeTabBody = <any>document.querySelector('.tabs-body.active');

          if (currentTab != activeTab.getAttribute('tab-target')) {
            nav.classList.add('active');
            tabsBody[index].classList.add('active');

            activeTabBody.classList.remove('active');
            activeTab.classList.remove('active');
          }
          //alert(nav.getAttribute('tab-target'))
        });
      });
    }

    if (options.type == 'single') {
      let tabsNav = document.querySelectorAll('.dialog-tabs .tab-nav');
      let tabsBody = document.querySelectorAll('.dialog-tabs .tabs-body');
      let activeTabNav = document.querySelector('.' + options.tab + '');
      let activeTabBody = document.querySelector('#' + options.tab + '');

      tabsNav.forEach((elem, i) => {
        tabsNav[i].classList.remove('active');
        tabsBody[i].classList.remove('active');
      });

      activeTabNav.classList.add('active');
      activeTabBody.classList.add('active');
    }
  }

  startBtnLoad(options: any = {}) {
    var btn = document.querySelector(options.class);
    if (btn != null) {
      const img = options.type == 'white' ? 'load-white.svg' : 'load-black.svg';
      const label = options.label ? options.label : 'Aguarde';

      btn.innerHTML =
        '<img style="width: ' +
        options.width +
        'px" src="' +
        this.app.IMG_PATH +
        img +
        '"> ' +
        label +
        '';
      btn.setAttribute('disabled', true);
    }
  }

  stopBtnLoad(options: any = {}) {
    var btn = document.querySelector(options.class);
    if (btn != null) {
      btn.innerHTML = options.label;
      btn.removeAttribute('disabled');
    }
  }

  print(options: any) {
    printJs(options);
    $(function () {
      $('[data-toggle="tooltip"]').tooltip('hide');
    });
  }

  async sendMail(form:FormGroup, order: any){
    const mail = form.value

    this.submitForm = true
    if(form.valid){
      this.btnSpinner(true, {btn:'.btnSendMail'})
      const res = await this.http.post<any>(this.API+'/orders/mail', {mail, order}).toPromise()
      //console.log(res)
      if(res.msg){
        $("#bsModalMail").modal("hide")
        this.btnSpinner(false, {btn:'.btnSendMail'})

        res.success ? form.reset() : false

        this.alertPush({
          type: res.success ? 'success' : 'error',
          position: 'top-center',
          delay: 2,
          msg: res.msg,
        });
      }
    }
  }
}
