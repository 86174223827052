import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'skeleton-load',
   templateUrl: './skeleton-load.html',
   styleUrls: ['./skeleton-load.css'],
})
export class SkeletonLoadComponent implements OnInit {
   @Input() options: any;
   items = []
   styleDefault: any

   constructor() {}

   ngOnInit(): void {
      for(let i=0; i < this.options.items; i++){
         this.items.push(i)
      }

   }
}
