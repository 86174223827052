import { WebSocketService } from './../../shared/socket.service';
import { UsersService } from './../users/users.service';
import { SharedService } from './../../shared/shared.service';
import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

import { AppService } from './../../app.service';
import { AuthService } from './../auth/auth.service';

declare var $: any;
declare var closeItems: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.html',
  styleUrls: ['./header.css'],
})
export class HeaderComponent implements OnInit {
  @Input() user: any;
  userNome: string;
  notifications: any[] = []
  userType = sessionStorage.getItem('userLevel');

  constructor(
    public app: AppService,
    public shared: SharedService,
    private authService: AuthService,
    private router: Router,
    public socket: WebSocketService
  ) {}

  signOut() {
    this.authService.signOut();
    this.router.navigate(['auth']);
  }

  closeMiniCart() {
    $('.mini-cart').removeClass('open');
  }

  getNotification(){
    this.app.getNotifications().then(res => {
      this.notifications = res
    })
  }

  ngOnInit(): void {
    if(this.userType == 'Bearer'){
      this.getNotification()
      this.socket.conn.onmessage = (e) => {
        let data = JSON.parse(e.data);
        this.getNotification()
      };
    }
  }
}
