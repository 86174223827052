<div id="main" class="layout-column flex">
  <div id="header" class="page-header">
    <div class="navbar navbar-expand-lg" style="background-color: #f1f1f1;">
      <a routerLink="/" class="navbar-brand">
        <!--<img src="<?=ADMIN_PATH?>/assets/img/logo.png" alt="...">-->
        <span class="hidden-folded d-inline l-s-n-1x">{{user.info.nome}}</span>
      </a>

      <!-- Navbar collapse -->
      <!--<div class="collapse navbar-collapse order-2 order-lg-1" id="navbarToggler"
         hidden="true">
            <ul class="navbar-nav mt-lg-0 mx-0 mx-lg-2 text-hover-primary">
               <li class="nav-item dropdown">
                  <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Opções</a>
                  <div class="dropdown-menu dropdown-menu-right mt-3" role="menu">
                     <a href="" target="blank" class="dropdown-item">Visitar Site </a>
                  </div>
               </li>
            </ul>

            <form class="input-group m-2 my-lg-0 flex" style="display: none">
               <div class="input-group-prepend">
                  <button type="button" class="btn no-shadow bg-dark no-bg px-0 text-inherit"> <i
                        data-feather="search"></i></button>
               </div>
               <input type="text" class="form-control no-border no-shadow no-bg typeahead"
                  placeholder="Pesquise alguma coisa" data-plugin="typeahead" data-api="">
            </form>
         </div>-->

      <ul class="nav navbar-menu order-1 order-lg-2">

        <!-- Notification -->
        <li class="nav-item dropdown">
          <a class="nav-link px-2 mr-lg-2" data-toggle="dropdown">
            <i class="fa fa-bell"></i>
            <span class="badge badge-pill badge-up bg-primary">
              {{notifications.length}}
            </span>
          </a>

          <div class="dropdown-menu dropdown-menu-right mt-3 w-md animate fadeIn p-0"
          *ngIf="notifications.length > 0">
            <div class="scrollable hover"
            style="max-height: 450px;">
              <div class="list list-row">
                <div class="list-item" data-id="11" *ngFor="let item of notifications">
                  <div style="vertical-align: top;">
                    <span class="w-32 avatar gd-info"><i class="fa fa-bell"></i></span>
                  </div>
                  <div class="flex">
                    <strong class="" style="font-size: 12px;">
                      {{item.content.title}}<br>
                    </strong>

                    <small style="font-size: 11px;">{{item.content.desc}}</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex px-3 py-2 b-t">
              <div class="flex">
                <span>{{notifications.length}} Notificações</span>
              </div>
              <!--<a href="">Ver Todas <i class="fa fa-angle-right text-muted"></i></a>-->
            </div>
          </div>
        </li>

        <!-- User dropdown menu -->
        <li class="nav-item dropdown">
          <a href="#" data-toggle="dropdown" class="nav-link d-flex align-items-center px-2 text-color">
            <span class="avatar w-24" style="margin: -2px">
              <img src="{{app.IMG_PATH}}user.png" alt="...">
            </span>
          </a>

          <div class="dropdown-menu dropdown-menu-right w mt-3 animate fadeIn">
            <a class="dropdown-item">
              <span>{{user.info.nome}}</span>
            </a>
            <!--<a class="dropdown-item" href="">
                       <span class="badge bg-success text-uppercase">Upgrade</span> <span>to Pro</span>
                   </a>-->

            <!--<div class="dropdown-divider"></div>
                   <a class="dropdown-item" href="perfil"><span>Perfil</span> </a>-->

            <!--<a class="dropdown-item d-flex" href="">
                       <span class="flex">Invoice</span>
                       <span><b class="badge badge-pill gd-warning">5</b></span>
                   </a>-->

            <!--<a class="dropdown-item" href="">Precisa de Ajuda?</a>
                   <div class="dropdown-divider"></div>-->

            <a (click)="signOut()" class="dropdown-item">Sair</a>
          </div>
        </li>
        <!-- Navarbar toggle btn -->
        <!--<li class="nav-item d-lg-none">
            <a href="#" class="nav-link px-2" data-toggle="collapse" data-toggle-class
               data-target="#navbarToggler"><i class="fa fa-search"></i></a>
         </li>-->

        <li class="nav-item d-lg-none">
          <a class="nav-link px-1" data-toggle="modal" data-target="#aside"><i class="fa fa-bars"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>

</div>
