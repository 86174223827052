<div class="flex">
   <div class="w-xl w-auto-sm mx-auto py-5">
      <div class="p-4 d-flex flex-column h-100">
         <a href="" class="navbar-brand align-self-center">
            <img src="{{app.filesPath}}logo/logo.png" alt="">
         </a>
      </div>

      <div class="col-md-12 col-12">
         <div class="alertAuth"></div>
         <div class="card bg-dark">
            <div id="content-body">
               <div class="p-3 p-md-5 text-center">
                  <h5 class="text-muted">Bem-vindo</h5>
                  <p class="">
                     <small class="text-muted">Faça login para acessar a sua conta</small>
                  </p>

                  <form [formGroup]="authFormGroup" (ngSubmit)="signIn()">
                     <div class="form-group">
                        <input type="email" name="" formControlName="login" class="form-control"
                        [ngClass]="form.classError(submitForm, authForm.login)"
                        placeholder="Email">
                        <small class="text-danger">
                           {{form.validate('required', submitForm, authForm.login, null)}}
                        </small>
                     </div>

                     <div class="form-group">
                        <input type="password" name="" formControlName="password" class="form-control"
                        [ngClass]="form.classError(submitForm, authForm.password)"
                        placeholder="Senha">
                        <small class="text-danger">
                           {{form.validate('required', submitForm, authForm.password, null)}}
                        </small>
                     </div>

                     <button type="submit" class="btn btn-primary mb-4 btnAuthSpinner"
                     style="width: 100%;">Entrar </button>

                     <!--<div>Não possui uma conta? <a href="" class="text-primary">Criar conta</a></div>-->
                  </form>
               </div>
            </div>
         </div>
      </div>

      <div class="text-center text-muted" style="display: none;">
         &copy; Copyright
         2020. <a href="https://angolasites.com" target="blank">Angola Sites</a>
      </div>
   </div>
</div>
