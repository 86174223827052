import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';

import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly API = `${environment.api}`;
  signed = new EventEmitter<boolean>();

  constructor(private route: Router, private http: HttpClient) {}

  async authenticate(url:string, values: any): Promise<any> {
    const auth = await this.http.post<any>(this.API+url, values)
      .toPromise();

    if(auth.token){
      sessionStorage.setItem('userToken', auth.token);
      sessionStorage.setItem('userLevel', auth.level);
      sessionStorage.setItem('userId', auth.id);
      sessionStorage.setItem('permissaoId', auth.permissaoId);
    }
    return auth;
  }

  getAuthorizationToken() {
    return sessionStorage.getItem('userToken');
  }

  isAuthenticated() {
    const token = this.getAuthorizationToken();
    if (token && this.isTokenExpired(token) == false) {
      return true;
    }
    return false;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) {
      return true;
    }

    const decoded: any = jwt_decode(token);
    //this.info = decoded

    if (decoded.exp === undefined) {
      return true;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  signOut() {
    sessionStorage.removeItem('userToken');
    sessionStorage.removeItem('userLevel');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('permissaoId');
    sessionStorage.clear();
    this.signed.emit(false);
  }
}
