import { PermissoesService } from './../permissoes/permissoes.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ProdutosService } from './../produtos/produtos.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { StoreService } from './../store/store.service';
import { UsersService } from './../users/users.service';
import { OrdersService } from './../orders/orders.service';
import { AppService } from './../../app.service';

declare var $: any;
declare var elementCarousel: any;

@Component({
   selector: 'app-home',
   templateUrl: './dashboard.html',
   styleUrls: ['./dashboard.css'],
})
export class DashboardComponent implements OnInit {
   visible: boolean = false;
   loader: boolean = true;
   orders: any = {};
   permissoes: any = null;
   userType = sessionStorage.getItem('userLevel');
   dashboard: object = {}

   constructor(
      public app: AppService,
      public shared: SharedService,
      private orderService: OrdersService,
      private usersService: UsersService,
      private storeService: StoreService,
      private produtoService: ProdutosService,
      private permService: PermissoesService,
   ) {}

   async initDashboard(perms: any){
      var level = this.userType
      const users =  await this.usersService.select()
      const orders =  await this.orderService.getCurrentOrders()
      const lojas =  await this.storeService.select()
      const produtos =  await this.produtoService.select()

      this.dashboard = {
         defaultShow: false,
         default : [
            {
               title:"Utilizadores",
               value: users.length,
               icon:"fa fa-users", bg:'gd-success',
               url:'users',
               allowed: level == 'Bearer' ? true
                  : perms.dashboard && perms.dashboard[0].label == 'ver total utilizadores'
                  && perms.dashboard[0].value
            },
            {
               title:"Produtos",
               value: produtos.length,
               icon:"fa fa-barcode", bg:'gd-secondary',
               url:'produtos',
               allowed: level == 'Bearer' ? true
                  : perms.dashboard && perms.dashboard[2].label == 'ver total produtos'
                  && perms.dashboard[2].value
            },
            {
               title:"Lojas",
               value: lojas.length,
               icon:"fa fa-store", bg:'gd-primary',
               url:'lojas',
               allowed: level == 'Bearer' ? true
                  : perms.dashboard && perms.dashboard[1].label == 'ver total lojas'
                  && perms.dashboard[1].value
            },
            {
               title:"Novas encomendas",
               value: orders.num,
               icon:"fa fa-briefcase", bg:'gd-success',
               url:'encomendas',
               allowed: level == 'Bearer' ? true
                  : perms.dashboard && perms.dashboard[3].label == 'ver total encomendas recentes'
                  && perms.dashboard[3].value
            },
         ],
         /*ordersShow: false,
         orders : [
            {
               title:"Novas encomendas",
               value: orders ? orders.length : 0,
               icon:"fa fa-users", bg:'gd-success',
               url:'encomendas',
               allowed: level == 'Bearer' ? true
                  : perms.dashboard && perms.dashboard[4].label == 'Ver total encomendas Geral'
                  && perms.dashboard[4].value
            },
         ]*/
      }

      this.dashboard['default'].forEach(elem => {
         if(elem.allowed){ this.dashboard['defaultShow'] = true }
      })
   }

   ngOnInit() {
      this.permService.requiredList().then((perms) => {
         this.initDashboard(perms)
      });
   }
}
