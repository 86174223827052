import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
   providedIn: 'root',
})
export class PermissoesService {
   private readonly API = `${environment.api}/permissoes`;
   chenged = new EventEmitter<boolean>()

   constructor(private http: HttpClient) {}

   async select(): Promise<any> {
      return await this.http.get<any[]>(this.API).toPromise();
   }

   async edit(values: any): Promise<any> {
      return await this.http.post<any>(this.API + '/edit', values).toPromise();
   }

   async create(values: any): Promise<any> {
      return await this.http
         .post<any>(this.API + '/create', values)
         .toPromise();
   }

   async update(values: any): Promise<any> {
      return await this.http
         .put<any>(this.API + '/update', values)
         .toPromise();
   }

   async delete(values: any): Promise<any> {
      return await this.http
         .post<any>(this.API + '/delete', {
            ids: values,
         })
         .toPromise();
   }

   async requiredList(): Promise<any>{
      return await this.http.get<any>(this.API + '/required').toPromise();
   }

   async check(values: any): Promise<any>{
      return await this.http.post<any>(this.API+'/check', values).toPromise();
   }



   listPermissoes(){
      return [
         {
            type: 'Dashboard',
            items: [
               {label: "Ver total utilizadores", value: false},
               {label: "Ver total Lojas", value: false},
               {label: "Ver total produtos", value: false},
               {label: "Ver total encomendas recentes", value: false},
               {label: "Ver total encomendas Geral", value: false},
               {label: "Ver valor total Encomendas Recentes", value: false},
            ]
         },
         {
            type: 'Categorias',
            items: [
               {label: "Visualizar", value: false},
               {label: "Adicionar", value: false},
               {label: "Actualizar", value: false},
               {label: "Eliminar", value: false}
            ]
         },
         {
            type: 'Produtos',
            items: [
               {label: "Visualizar", value: false},
               {label: "Adicionar", value: false},
               {label: "Actualizar", value: false},
               {label: "Eliminar", value: false},
            ]
         },
         {
            type: 'Lojas',
            items: [
               {label: "Visualizar", value: false},
               {label: "Adicionar", value: false},
               {label: "Actualizar", value: false},
               {label: "Eliminar", value: false},
            ]
         },
         {
            type: 'Encomendas',
            items: [
               {label: "Visualizar", value: false},
               {label: "Adicionar", value: false},
               {label: "Actualizar", value: false},
               {label: "Eliminar", value: false},
               {label: "Validar Stock", value: false},
            ]
         },
         {
            type: 'Contas',
            items: [
               {label: "Visualizar", value: false},
               {label: "Adicionar", value: false},
               {label: "Actualizar", value: false},
               {label: "Eliminar", value: false},
            ]
         },
         {
            type: 'Utilizadores',
            items: [
               {label: "Visualizar", value: false},
               {label: "Adicionar", value: false},
               {label: "Actualizar", value: false},
               {label: "Eliminar", value: false},
            ]
         },
         {
            type: 'Permissões',
            items: [
               {label: "Visualizar", value: false},
               {label: "Adicionar", value: false},
               {label: "Actualizar", value: false},
               {label: "Eliminar", value: false},
            ]
         },
         {
            type: 'Definições',
            items: [
               {label: "Visualizar", value: false},
               {label: "Actualizar", value: false}
            ]
         },
      ]
   }
}
