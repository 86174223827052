import { Component, OnInit } from '@angular/core';

declare var $: any
declare var initDataTable: any

@Component({
   selector: 'app-table-filter',
   templateUrl: './table-filter.html',
   styleUrls: ['./table-filter.css']
})
export class TableFilterComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {

   }
}
