<div ngClass="app-spinner" style="color: #06b2e6;"></div>
<ngx-loading-bar [includeSpinner]="false" ref="router"></ngx-loading-bar>

<div class="layout-row" *ngIf="isAuth && user.id && permissoes != null ">
  <app-nav [user]="user" [permissoes]="permissoes"></app-nav>

  <div id="main" class="layout-column flex">
    <app-header [user]="user"></app-header>
    <router-outlet></router-outlet>
  </div>
</div>

<router-outlet *ngIf="!isAuth"></router-outlet>
