import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { SharedService } from './shared.service';
import { FormValidationService } from './forms/form.validation';

import { SkeletonLoadComponent } from './skeleton-load/skeleton-load.component';
import { SpinnersComponent } from './spinners/spinners.component';
import { TableFilterComponent } from './widgets/table-filter/table-filter.component';
import { UploadComponent } from './upload/upload.component';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { NotAccessComponent } from './error/not-access/not-access.component';

@NgModule({
  declarations: [
    SkeletonLoadComponent,
    SpinnersComponent,
    TableFilterComponent,
    UploadComponent,
    NotFoundComponent,
    NotAccessComponent,
  ],
  imports: [CommonModule, FormsModule, NgbModule, NgxSkeletonLoaderModule],
  exports: [
    SkeletonLoadComponent,
    SpinnersComponent,
    TableFilterComponent,
    UploadComponent,
  ],
  providers: [FormValidationService, SharedService],
})
export class SharedModule {
  /*constructor(
      @Optional() @SkipSelf() parentModule: SharedModule
   ){
      if(parentModule){
         throw new Error('O módlulo Shared já está a ser lido e só pode ser importado uma única vez');
      }
   }*/
}
