<!--Lista de produto-->
<ng-container *ngIf="options.type == 'produtoList'">
   <div class="row">
      <div class="col-md-{{options.colums}}" *ngFor="let item of items">
         <ngx-skeleton-loader appearance="square"
         animation="pulse" [theme]="{ width: '100%', height: '40vh'}"></ngx-skeleton-loader>
         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '100%', height: '10px'}"></ngx-skeleton-loader>
         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '70%', height: '10px'}"></ngx-skeleton-loader>
         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '40%', height: '10px'}"></ngx-skeleton-loader>
      </div>
   </div>
</ng-container>

<!--Detalhes de produto-->
<ng-container *ngIf="options.type == 'produtoDeth'">
   <br><br>
   <div class="row">
      <div class="col-md-5">
         <ngx-skeleton-loader appearance="square"
         animation="pulse" [theme]="{width: '100%', height: '60vh'}"></ngx-skeleton-loader>
      </div>

      <div class="col-md-6">
         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '25%', display:'block', height: '20px'}"></ngx-skeleton-loader>
         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '55%', display:'block', height: '10px'}"></ngx-skeleton-loader>
         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '20%', display:'block', height: '10px'}"></ngx-skeleton-loader><br>

         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '30%', display:'block', height: '10px'}"></ngx-skeleton-loader><br>

         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '35%', display:'block', height: '7px'}"></ngx-skeleton-loader>
         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '45%', display:'block', height: '7px'}"></ngx-skeleton-loader>
         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '50%', display:'block', height: '7px'}"></ngx-skeleton-loader>
         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '45%', display:'block', height: '7px'}"></ngx-skeleton-loader>
         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '25%', display:'block', height: '7px'}"></ngx-skeleton-loader><br>

         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '30%', display:'block', height: '10px'}"></ngx-skeleton-loader><br>

         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '18%', marginRight:'5px', height: '30px'}"></ngx-skeleton-loader>
         <ngx-skeleton-loader appearance="line"
         animation="pulse" [theme]="{ width: '25%', borderRadius:'10px', height: '30px'}"></ngx-skeleton-loader>
      </div>
   </div>
</ng-container>


<!--Multi linhas-->
<ng-container  *ngIf="options.type == 'categorias'">
   <br>
   <div *ngFor="let item of items">
      <ngx-skeleton-loader appearance="line"
      animation="pulse" [theme]="{ marginLeft:'10px', display:'block', width: '27%', height: '10px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line"
      animation="pulse" [theme]="{ marginLeft:'10px', display:'block', width: '55%', height: '10px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line"
      animation="pulse" [theme]="{ marginLeft:'10px', display:'block', width: '60%', height: '10px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader appearance="line"
      animation="pulse" [theme]="{ marginLeft:'10px', display:'block', width: '75%', height: '10px'}"></ngx-skeleton-loader>

   </div>
</ng-container>

<ng-container  *ngIf="options.type == 'line'">
   <ngx-skeleton-loader appearance="line" *ngFor="let item of items"
      animation="pulse" [theme]="{
         display:'block', width: options.width, height: options.height
      }">
   </ngx-skeleton-loader>
</ng-container>


<ng-container *ngIf="options.type == 'cartList'">
   <table style="width: 100%;">
      <thead>
         <th style="width: 10%;"></th>
         <th class="text-left">Produto</th>
         <th class="text-right">Preço</th>
         <th class="text-right" style="min-width: 80px;">Qtd</th>
         <th class="text-right">Total</th>
      </thead>

      <tbody *ngFor="let item of items">
         <td class="text-left" style="padding-right: 10px;">
            <ngx-skeleton-loader appearance="circle"
            animation="pulse" [theme]="{ width: '100%', height: '10vh'}"></ngx-skeleton-loader>
         </td>
         <td class="text-left" valign="middle">
            <ngx-skeleton-loader appearance="square"
            animation="pulse" [theme]="{ width: '30vh', height: '10px'}"></ngx-skeleton-loader>
         </td>

         <td class="text-right" valign="middle">
            <ngx-skeleton-loader appearance="square"
            animation="pulse" [theme]="{ width: '15vh', height: '10px'}"></ngx-skeleton-loader>
         </td>

         <td class="text-right" valign="middle">
            <ngx-skeleton-loader appearance="square"
            animation="pulse" [theme]="{ width: '5vh', height: '25px'}"></ngx-skeleton-loader>
         </td>

         <td class="text-right" valign="middle">
            <ngx-skeleton-loader appearance="square"
            animation="pulse" [theme]="{ width: '18vh', height: '10px'}"></ngx-skeleton-loader>
         </td>
      </tbody>
   </table>
</ng-container>
