import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root',
})

export class WebSocketService {
  private readonly server = `${environment.socketServer}`;
  public conn: WebSocket;

  constructor() {
    const url = location.host;
    //alert(location.host)
    this.conn = new WebSocket('ws://localhost:7000');
    this.conn.onopen = function (e) {
      console.log('Conexão estabelecida ss');
    };

    this.conn.onclose = function(event){
      if(event.wasClean){
        console.log('Desconectadof');
      }else{
        console.log('Desconectado1');
      }
    }
  }

  init() {
    //console.log(this.conn.CONNECTING)
    /*this.conn.onopen = function (e) {
         console.log(e);
      };*/
  }

  send(values: any) {
    //this.init()
    //this.conn.close(1000)

    //this.conn = new WebSocket('ws://localhost:7000');
    this.conn.send(values);
  }
}
