import { AppService } from './../../app.service';
import { delay } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { SharedService } from './../../shared/shared.service';
import { FormValidationService } from './../../shared/forms/form.validation';

import { AuthService } from './auth.service';

@Component({
   selector: 'app-auth',
   templateUrl: './auth.html'
})
export class AuthComponent implements OnInit {
   authFormGroup: FormGroup
   submitForm: boolean

   constructor(
      public app: AppService,
      public shared: SharedService,
      public form: FormValidationService,
      private authService: AuthService,
      private formBuilder: FormBuilder,
      private router: Router
   ) {
     console.log(router.url)
   }

   async signIn(){
      this.submitForm = true
      if(this.authFormGroup.valid){
         this.shared.btnSpinner(true, {btn:".btnAuthSpinner"})

         await this.authService.authenticate(this.router.url,this.authFormGroup.value)
         .then(res =>{
            this.shared.btnSpinner(false, {btn:".btnAuthSpinner"})
            this.shared.alertMsg({
               show: true, delay:500, div:'.alertAuth',
               type: res.token ? 'success' : 'danger',
               msg: res.token ? "Autenticação efectuada com sucesso": res.msg
            })

            if(res.token){
               setTimeout(()=>{
                  window.location.href = "dashboard"
                  //this.router.navigate(['dashboard'])
                  //this.authService.signed.emit(true)
               },1000)
            }
         })
      }
   }

   ngOnInit(){
      this.authFormGroup = this.formBuilder.group({
         login: ['', Validators.required],
         password: ['', Validators.required],
         origin:['admin']
      })
   }

   get authForm(){ return this.authFormGroup.controls }

}
