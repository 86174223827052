import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment.prod';
import { Injectable } from '@angular/core';

@Injectable({
   providedIn: 'root',
})
export class UsersService {
   private readonly API = `${environment.api}/users`

   constructor(
      private http: HttpClient
   ){}

   async select(): Promise<any>{
      return await this.http.get<any[]>(this.API).toPromise()
   }

   async authenticatedUser(): Promise<any>{
      return await this.http.get<any[]>(`${environment.api}/auth/user`).toPromise()
      //console.log(user)
   }

   async create(values: any): Promise<any>{
      return await this.http.post<any>(this.API+"/create", values).toPromise()
   }

   async edit(values: any): Promise<any> {
      return await this.http.post<any>(this.API + '/edit', values).toPromise();
   }

   async update(values: any): Promise<any>{
      return await this.http.put<any>(this.API+"/update", values).toPromise()
   }

   async delete(values: any): Promise<any>{
      return await this.http.post<any>(this.API+"/delete", {
         ids: values
      }).toPromise()
   }
}
