import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { AppService } from './../../app.service';

@Injectable({
   providedIn: 'root',
})
export class StoreService {

   private readonly API = `${environment.api}/lojas`
   userType = sessionStorage.getItem('userLevel');
   userId = sessionStorage.getItem('userId');

   constructor(
      private http: HttpClient,
      private app: AppService
   ){}

   async select(): Promise<any>{
      //alert(this.userId)
      const res = []
      const result = await this.http.get<any[]>(this.API).toPromise()

      if(this.userType != this.app.typeUser){
         return result
      }

      if(this.userType == this.app.typeUser){
         result.forEach(elem => {
            if(parseInt(elem.conta_id) == parseInt(this.userId)){
               res.push(elem)
            }
         })
         return res
      }
   }

   async selectBy(obj: any): Promise<any>{
      return await this.http.post<any>(this.API+"/edit", obj).toPromise()
   }

   async create(values: any): Promise<any>{
      return await this.http.post<any>(this.API+"/create", values).toPromise()
   }

   async update(id:number, values: any): Promise<any>{
      return await this.http.put<any>(this.API+"/update", values).toPromise()
   }

   async delete(values: any): Promise<any>{
      return await this.http.post<any>(this.API+"/delete", {
         ids: values
      }).toPromise()
   }
}
