import { Component, OnInit } from '@angular/core';
import { SharedService } from './../../shared/shared.service';
import { AppService } from './../../app.service';

declare var $: any

@Component({
   selector: 'app-footer',
   templateUrl: './footer.html',
   styleUrls: ['./footer.css']
})
export class FooterComponent implements OnInit {

   navFooter: object
   constructor(
      public app: AppService,
      public shared: SharedService
   ) { }

   ngOnInit() {
      this.navFooter = {}

   }

}
