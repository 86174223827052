import {
   ActivatedRouteSnapshot,
   CanActivate,
   Router,
   RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthService } from '../components/auth/auth.service';

@Injectable({providedIn: "root"})
export class AuthGuard implements CanActivate {
   constructor(
      private authService: AuthService,
      private router: Router
   ) {}

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

      if(!this.authService.isAuthenticated()){
         this.router.navigate(['/auth'])
      }

      return this.authService.isAuthenticated()
   }
}
