<div>
   <div class="row no-gutters">
      <div class="col-12 col-sm-12 col-md-12" style="margin-top: 10%;">
         <div class="padding text-center">
            <h1 class="">
               Infelizmente não lhe é permitido visualizar<br>
               o conteúdo desejado.
            </h1>
            <p class="my-5 text-muted h4">-- 403 --</p>
         </div>
      </div>

   </div>
</div>
