import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate, CanDeactivate } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { DashboardComponent } from './components/dashobard/dashboard.component';
import { NotFoundComponent } from './shared/error/not-found/not-found.component';
import { RedirectComponent } from './redirect/redirect.component';
import { RecoveryComponent } from './components/auth/recovery/recovery.component';
import { AuthComponent } from './components/auth/auth.component';
import { NotAccessComponent } from './shared/error/not-access/not-access.component';

const AppRoutes: Routes = [
  { path: 'auth',  redirectTo:'auth/login', pathMatch:'full'},
  { path: 'auth', component: AuthComponent, children:[
    { path: 'login', component: AuthComponent },
    { path: 'logistas', component: AuthComponent },
  ]},

  {
    path: '', component: DashboardComponent,
    children: [
      { path: '', component: DashboardComponent },
      { path: 'dashboard', component: DashboardComponent }
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'categorias', loadChildren: () => import('./components/categorias/categorias.module')
      .then(module => module.CategoriasModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'contas', loadChildren: () => import('./components/contas/contas.module')
      .then(module => module.ContaModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'lojas', loadChildren: () => import('./components/store/store.module')
      .then(module => module.StoreModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos', loadChildren: () => import('./components/produtos/produtos.module')
      .then(module => module.ProdutosModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'encomendas', loadChildren: () => import('./components/orders/orders.module')
      .then(module => module.OrdersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'delivery', loadChildren: () => import('./components/delivery/delivery.module')
      .then(module => module.DeliveryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'relatorios', loadChildren: () => import('./components/relatorios/relatorios.module')
      .then(module => module.RelatoriosModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users', loadChildren: () => import('./components/users/users.module')
      .then(module => module.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'permissoes', loadChildren: () => import('./components/permissoes/permissoes.module')
      .then(module => module.PermissoesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings', loadChildren: () => import('./components/settings/settings.module')
      .then(module => module.SettingsModule),
    canActivate: [AuthGuard]
  },
  { path: 'redirect', component: RedirectComponent },

  { path: 'access-denid', component: NotAccessComponent },
  { path: '**', component: NotFoundComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, {
    onSameUrlNavigation: "reload"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { routes: object }

